import React, { memo } from 'react';
import SearchFilterOld from '@crosslud/components/SearchFilterOld/SearchFilterOld';
import SearchOrder from '@crosslud/components/SearchOrder/SearchOrder';

const ScrollListHeader = memo(({
  titleComponent,
  sorter = true, 
  filter = true, 
  extraComponent,
  hook,
  category,
  fieldType
}) => {
  return (
    <PageTitle 
      titleComponent={titleComponent} 
      extraComponent={extraComponent} 
      hook={hook} 
      category={category} 
      fieldType={fieldType} 
      sorter={sorter} 
      filter={filter} 
    />
  )
});

const PageTitle = memo(({titleComponent, extraComponent, hook, category, fieldType, sorter, filter}) => {
  return (
    <div className="br-pagetitle pd-x-15 bg-black-1">
      {titleComponent}
      <div className="btn-group pos-absolute r-30">
        {extraComponent}
        {sorter && <SearchOrder hook={hook} category={category} fieldType={fieldType} />}
        {filter && <SearchFilterOld hook={hook} category={category} fieldType={fieldType} />}
      </div>
    </div>
  )
})

export default ScrollListHeader;
