/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {ScrollView, StyleSheet, Platform} from 'react-native';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
//import {Badge} from 'react-native-elements';
import {
  Modal, 
  Button,
  CText,
  View,
  TouchableOpacity,
  IconText,
  FormRowBuilder
} from '@crosslud/components';
import Field from '@crosslud/helpers/Field';
import {searchFilter} from '@crosslud/redux/SearchFilter/search-filter-actions';
import Colors from '@crosslud/constants/Colors';
import { useTranslation } from 'react-i18next';
import { CommonAPI } from '@crosslud/apis/CommonAPI';
import { KeyboardAvoidingView } from 'react-native';

const SearchFilter = ({hook, category, fieldType, ignores = []}) => {
  const {t} = useTranslation();

  // DISPATCH
  const dispatch = useDispatch();

  // STATE
  const [filterFields, setFilterFields] = useState();
  const [sortfield, setSortField] = useState();
  const [showModal, setShowModal] = useState(false);

  // FORM
  const {
    control,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm();

  // ACTION & API

  // Filter fields
  const fetchCategorySearchFormFields = async (categoryId, fieldGroup) => {
    const response = await CommonAPI.getSearchFormFields(categoryId, fieldGroup);

    if (response?.status) {
      var formattedFields = [];

      response?.payload?.map((xContainerField) => {
        if(!ignores || !ignores?.includes(xContainerField?.formField.reference)) {
          // Load field datas
          const fieldConfig = Field.fieldsDataLoader(xContainerField?.formField, categoryId);

          var formattedField = {
            reference: xContainerField?.formField.reference,
            type: xContainerField?.formField.fieldType,
            mappingType: xContainerField?.formField.mappingType === 'field' ? 'field' : 'tag',
            multiple: xContainerField?.formField.mappingType === 'manyToMany' ? true : false,
            label: xContainerField?.translatedName,
            option: {
              id: fieldConfig?.selector.id,
              label: fieldConfig?.selector.label,
              icon: fieldConfig?.selector?.icon,
              url: fieldConfig?.urlParams,
            }
          };

          formattedFields.push(formattedField);
        }
      });
      
      setFilterFields(formattedFields);
    }
  };

  // Sorter field
  const fetchCategorySearchSortFields = async (categoryId, fieldGroup) => {
    const response = await CommonAPI.getSearchSortFields(categoryId, fieldGroup);

    if (response?.status) {
      if (response?.payload?.length > 0) {
        var formattedField = {
          reference: 'orderBy',
          type: '',
          mappingType: 'select',
          label: t('me.search.filter.orderBy.label'),
          placeholder: t('me.search.filter.orderBy.placeholder'),
          option: {
            id: 'formField.reference',
            label: 'translatedName',
            data: response?.payload
          }
        };

        setSortField(formattedField);
      }
    }
  };

  // Init filter
  const initFilterFormValues = () => {
    filterFields?.map(input => {
      setValue(input.reference, hook.getFilters()?.[input.reference]);
    })
  }

  // Init sorter
  const initSortFormValue = () => {
    setValue(sortfield.reference, hook.getFilters()?.[sortfield.reference] ?? sortfield?.option?.data[0]);
  }

  // On submit
  const onSubmit = async e => {
    e.preventDefault();

    handleSubmit((data) => {
      dispatch(searchFilter(data, hook.getIdentifier(), hook.getScope()));

      setShowModal(false)
    })(e);
  }

  useEffect(() => {
    showModal && filterFields && initFilterFormValues();
  }, [filterFields, showModal]);

  useEffect(() => {
    showModal && sortfield && initSortFormValue();
  }, [sortfield, showModal]);

  useEffect(() => {
    category !== undefined && fetchCategorySearchFormFields(category, fieldType);
    category !== undefined && fetchCategorySearchSortFields(category, fieldType);
  }, [hook.getIdentifier(), hook.getScope(), category, fieldType]);

  // RENDER
  return (
    <>
      <TouchableOpacity style={styles.container} onPress={() => setShowModal(true)}>
        {hook.getCount() > 0 && (
          <View style={styles.badgeContainer}>
            {/*<Badge
              value={hook.getCount()}
              status="error"
              badgeStyle={{borderColor: 'none'}}
              textStyle={{fontWeight: 'bold'}}
            />*/}
          </View>
        )}
        <IconText icon={'funnel-sharp'} iconSize={32} color={'white'}/>
      </TouchableOpacity>
      <Modal
        show={showModal}
        handleClose={() => setShowModal(false)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 0}
          style={{ flex: 1 }}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            showsVerticalScrollIndicator={false}>
            <View style={styles.modaContainer}>
              <View style={styles.titleContainer}>
                <CText style={styles.title}>{t('fe.searchFilter.title').toUpperCase()}</CText>
              </View>
              <View style={styles.fieldsContainer}>
                {filterFields?.map(filterField => {
                  return <FormRowBuilder key={filterField.reference} input={filterField} control={control} />
                })}
              </View>
            </View>
          </ScrollView>
          <View style={{padding: 20, backgroundColor: 'rgba(0,0,0,0.5)'}}>
            {sortfield?.reference && (
              <FormRowBuilder key={sortfield.reference} input={sortfield} control={control} />
            )}
            <Button
              style={styles.button}
              title={t('fe.searchFilter.form.submit.button')}
              onPress={onSubmit}
            />
          </View>
        </KeyboardAvoidingView>
      </Modal>
    </>
  );
};

export default SearchFilter;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.main.button,
    borderWidth: 1,
    borderColor: '#fff'
  },
  badgeContainer: {
    position: 'absolute',
    alignItems: 'flex-end',
    flex: 1,
    top: -5,
    right: 0,
    width: 50,
    zIndex: 8888,
  },
  modaContainer: {
    flex: 1,
    paddingHorizontal: 20,
    paddingBottom: 40,
  },
  titleContainer: {
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: '#fff',
    fontSize: 18,
  },
  fieldsContainer: {
    flex: 1,
    marginTop: 20,
  },
  button: {
    paddingVertical: 15,
    backgroundColor: Colors.main.button
  }
});