/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {memo, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
  FormInputCheck,
  FormInputText,
  FormInputTag,
  FormInputDate,
  Modal
} from '@crosslud/components';
import {useDispatch} from 'react-redux';
import {searchFilter} from '@crosslud/redux/SearchFilter/search-filter-actions';
import Field from '@crosslud/helpers/Field';
import { useTranslation } from 'react-i18next';
import { CommonAPI } from '@crosslud/apis/CommonAPI';

const SearchFilterOld = ({hook, category, fieldType}) => {
  const {t} = useTranslation();

  // DISPATCH
  const dispatch = useDispatch();

  // STATE
  const [fields, setFields] = useState();
  const [showModal, setShowModal] = useState(false);

  // FORM
  const {
    control,
    handleSubmit,
    formState: {errors},
    getValues,
    setValue,
    watch,
  } = useForm();

  // ACTION & API
  const fetchCategorySearchFormFields = async (categoryId, fieldGroup) => {
    //var activityRef = activityHook.start();

    const response = await CommonAPI.getSearchFormFields(categoryId, fieldGroup);

    if (response?.status) {
      setFields(response?.payload);
    }

    //activityHook.stop(activityRef);
  };

  const initFormValues = () => {
    fields?.map(field => {
      var formField = field?.formField;

      setValue(formField.reference, Field.getDefaultDatas(hook.getFilters(), formField));
    })
  }

  const formFieldRender = formField => {
    const fieldData = Field.fieldsDataLoader(formField, category);

    return (
      <Controller
        control={control}
        name={formField.reference}
        render={({ field }) => (
          <>
            {!['field'].includes(formField?.mappingType) && (
              <FormInputTag
                {...field}
                itemsFromUrl={fieldData?.urlParams}
                dataFromUrl={formField?.apiCaller}
                idSelector={fieldData?.selector.id}
                labelSelector={fieldData?.selector.label}
                containerStyle={{
                  borderWidth: 1,
                  borderColor: 'rgba(255, 255, 255, 0.08)',
                }}
                inputStyle={{color: '#fff'}}
              />
            )}

            {['field'].includes(formField?.mappingType) && (
              <>
                {['string', 'text'].includes(formField?.fieldType) && (
                  <FormInputText {...field} multiple={formField.fieldType === 'text'} />
                )}

                {['datetime'].includes(formField?.fieldType) && (
                  <FormInputDate {...field} />
                )}

                {['boolean'].includes(formField?.fieldType) && (
                  <FormInputCheck 
                    {...field}
                    labelSelector={'reference'} 
                    idSelector={'id'} 
                  />
                )}
              </>
            )}
          </>
        )}
      />
    );
  };

  const formRowRender = (categoryContainerField) => {
    return (
      categoryContainerField?.formField.fieldType !== 'boolean' ? (
        <>
          <label>{categoryContainerField?.translatedName ? categoryContainerField.translatedName : <>&nbsp;</>}</label>
          <div className="form-group">
            {formFieldRender(categoryContainerField?.formField)}
          </div>
        </>
      ) : (
        <label className="ckbox">
          {formFieldRender(categoryContainerField?.formField)}
          <span>{categoryContainerField?.translatedName ? categoryContainerField.translatedName : <>&nbsp;</>}</span>
        </label>
      )
    );
  };

  // On submit
  const onSubmit = async e => {
    e.preventDefault();

    handleSubmit((data) => {
      dispatch(searchFilter(data, hook.getIdentifier(), hook.getScope()));

      setShowModal(false)
    })(e);
  };

  useEffect(() => {
    fields && initFormValues();
  }, [fields]);

  useEffect(() => {
    fetchCategorySearchFormFields(category, fieldType);
  }, [hook.getIdentifier(), hook.getScope()]);

  // RENDER
  return (
    fields && fields?.length > 0 &&
    <>
      <button type="button" className="btn btn-outline-light rounded-0" onClick={() => setShowModal(true)}>
        {hook.getCount() > 0 && (
          <span className="btn btn-danger btn-icon rounded-circle pos-absolute t--20">
            <div>{hook.getCount()}</div>
          </span>
        )}
        <i className="fa fa-th"></i>
      </button>

      <Modal className={"modal-lg"} show={showModal} handleClose={() => setShowModal(false)}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 bg-dark">
              <div className="container">
                <div className="row">
                  <div className="col text-center mg-t-30 mg-b-20">
                    <h3>{t('fe.searchFilter.title')}</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      {fields.map(field => {
                        return <div key={field.id} className="col-md-6 mg-b-10">{formRowRender(field)}</div>;
                      })}
                    </div>
                  </div>
                  <div className="col-12 mg-t-20 mg-b-30">
                    <button className="col btn btn-info" onClick={onSubmit}>{t('fe.searchFilter.form.submit.button')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default memo(SearchFilterOld);
