/* eslint-disable import/no-anonymous-default-export */
const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export default {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
  main: {
    primary: 'rgb(10, 132, 255)', /** ok */
    background: '#141d28', /** ok */
    blockBackground: '#1d2939', /** ok */
    card: 'rgba(0, 0, 0, 0.3)', /** ok */
    text: '#868ba1', /** ok */
    border: 'rgb(39, 39, 41)',
    notification: 'rgb(255, 69, 58)',
    button: '#138496',
    inactiveButton: '#868ba1',
    tint: tintColorLight,
  }
};
